import NextImage from 'next/legacy/image'
import useViewportSize from 'hooks/useViewportSize'

// The idea with this component is to get the best of both worlds when loading images
// We don't want to eagerly load all images at initial page load
// but we do want to eager load our images before the user scrolls them into view
// By default, NextImage has a lazyBoundary of 200px, but this value is too small.

// Additionally we can use this as a place to set a default loading image placeholder.

// priority="false" adds loading="lazy" to the img tag.
// priority="true" simply removes loading="lazy" from the img tag.

// lazyBoundary is what we want and it's built into next/image, it actually uses IntersectionObserver
// and the good news is that it can be set dynamically.

// This loader will prevent the image request being proxied through our server
// and instead go directly to the contentful image cdn.
const loader = ({ src, width }) => {
  if (src.endsWith('.svg')) {
    return `${src}?w=${width}`
  }
  return `${src}?fm=webp`
}

function Image(props) {
  // Use screen height to set lazyBoundary
  const { screenHeight } = useViewportSize()

  // There is this really awful reverse vignette effect that is baked into next/image placeholder.
  // So I matched the color and opacity of the placeholder 1x1 pixel image to match the vignette effect.
  const blurDataURL =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBgMAIAADcAMwsWy8IAAAAASUVORK5CYII='

  const imgProps = {
    lazyBoundary: `${screenHeight}px`,
  }

  // To prevent warnings about placeholder blur performance, do not set on small images.
  // I couldn't find a definition for small images, but 100 works for now.
  const { width, height, src } = props
  const minimumSize = 100
  if (
    !(
      typeof width === 'number' &&
      typeof height === 'number' &&
      width < minimumSize &&
      height < minimumSize
    ) &&
    !src.endsWith('.svg')
  ) {
    imgProps.placeholder = 'blur'
    imgProps.blurDataURL = blurDataURL
  }

  // Allow overriding any of the imgProps
  return <NextImage loader={loader} {...imgProps} {...props} />
}

export { Image }
